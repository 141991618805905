/**
 *		Ivar Aasengata 10
 *
 *  	author  Simon Dagfinrud
 *					Racer AS
 *  	      http://racer.no
 *
 *  	url     http://ivaraasengata.no
 *  	ver     1.0
 *  	cre     25. mai 2016
**/


// Using BEMIT naming convention, along with ITCSS - BEMITCSS
// BEMIT: http://www.jamesturneronline.net/beautifulweb/bemit-naming-convention.html
// ITCSS: http://www.creativebloq.com/web-design/manage-large-css-projects-itcss-101517528
// 				http://semonje.com/images/itcssmain.jpg

/*  Content
    1. settings
    2. tools
    3. generic
    4. elements
    5. objects
    6. components
    7. trumps
*/

// ---------------------------------------------------------------- //
// --- 1. SETTINGS ------------------------------------------------ //
// ---------------------------------------------------------------- //

@import "../css/normalize";

$rale: 'Raleway', sans-serif;
$fa: FontAwesome;

$tr: ease 0.3s all;
$fastr: ease 0.15s all;

$shortWidth: 750px;
$medWidth: 850px;
$minWidth: 320px;

// media queries
$screen-xl: 1500px;
$screen-lg: 1199px;
$screen-md: 991px;
$screen-sm: 767px;
$screen-xs: 479px;

// menu
$menuHeight: 100px;
$menuHeightMob: 70px;

// menubutton
// tweak these to get the right size and proportions
$mbHeight: $menuHeight / 2.3;
$mbWidth:  $menuHeight / 2.8;


// color
  // colorwheel
	$color1: #2c69de;
  $color2: #6033e2;
  $color3: #8c235f;
	$color4: #333333;
	$color5: #e9e9e9;
	$color6: #ffffff;

	// main colors
	$main: $color1;

  // headings and text
  $dark: $color4;
	$light: $color5;
	$white: $color6;


// ---------------------------------------------------------------- //
// --- 2. TOOLS --------------------------------------------------- //
// ---------------------------------------------------------------- //

// Round decimals
@function decimal-round ($number, $digits: 0, $mode: round) {
		$n: 1;
		// $number must be a number
		@if type-of($number) != number {
				@warn '#{ $number } is not a number.';
				@return $number;
		}
		// $digits must be a unitless number
		@if type-of($digits) != number {
				@warn '#{ $digits } is not a number.';
				@return $number;
		} @else if not unitless($digits) {
				@warn '#{ $digits } has a unit.';
				@return $number;
		}
		@for $i from 1 through $digits {
				$n: $n * 10;
		}
		@if $mode == round {
				@return round($number * $n) / $n;
		} @else if $mode == ceil {
				@return ceil($number * $n) / $n;
		} @else if $mode == floor {
				@return floor($number * $n) / $n;
		} @else {
				@warn '#{ $mode } is undefined keyword.';
				@return $number;
		}
}

// Media queries
@mixin mq($point,$minMax:null) {

	// Check if is min-width, if not then max-width.
	// Add 1px on min-width to correspond with Bootstrap.
	$corr: 0px !default;

	@if $minMax == min {
		$minMax: 'min-width:';
		$corr: 1px;
	}
	@else {
		$minMax: 'max-width:';
		$corr: 0px;
	}

	$xl: "#{$minMax} #{$screen-xl + $corr}";
	$lg: "#{$minMax} #{$screen-lg + $corr}";
	$md: "#{$minMax} #{$screen-md + $corr}";
	$sm: "#{$minMax} #{$screen-sm + $corr}";
	$xs: "#{$minMax} #{$screen-xs + $corr}";

	$ps: "#{$minMax} #{$point + $corr}";

	@if $point == xl {
		@media screen and (#{$xl}) { @content; }
	}
	@else if $point == lg {
		@media screen and (#{$lg}) { @content; }
	}
	@else if $point == md {
		@media screen and (#{$md}) { @content; }
	}
	@else if $point == sm {
		@media screen and (#{$sm})  { @content; }
	}
	@else if $point == xs {
		@media screen and (#{$xs})  { @content; }
	}
	@else {
		@media screen and (#{$ps}) { @content; }
	}
}


// Centering content horizontically
.u-center {
  width: 1500px;
  max-width: 92%;
  margin-left: auto;
  margin-right: auto;
  @include mq(xs) {
    max-width: 90%;
  }
}
.u-center--unmob-sm {
	@include mq(sm) {
		max-width: 100%;
	}
}

// First / last item on page
.u-top-m { margin-top: $menuHeight; }
.u-top-p { padding-top: $menuHeight; }
.u-bot-m { margin-bottom: $menuHeight; }
.u-bot-p { padding-bottom: $menuHeight; }

.u-top-m2 { @extend .u-top-m; @include mq(sm,min) { margin-top: $menuHeight*1.5; } }
.u-top-p2 { @extend .u-top-p; @include mq(sm,min) { padding-top: $menuHeight*1.5; } }
.u-bot-m2 { @extend .u-bot-m; @include mq(sm,min) { margin-bottom: $menuHeight*1.5; } }
.u-bot-p2 { @extend .u-bot-p; @include mq(sm,min) { padding-bottom: $menuHeight*1.5; } }


// Padding
.u-padding {
	padding: 2rem;
  box-sizing: border-box;
}
.u-padding_lr {
	padding-left: 2rem;
	padding-right: 2rem;
	box-sizing: border-box;
}
.u-padding_full {
	padding: 0 2rem 0 2rem;
	box-sizing: border-box;
	@include mq(md) {
		padding: 0 3% 0 3%;
	}
}
.u-padding_tp {
	padding-top: 2rem;
	padding-bottom: 2rem;
	@include mq(sm) {
		padding-top: 1.5rem;
		padding-bottom: 1.5rem;
	}
}
// Remove padding
.u-nopad {
	padding-left: 0;
	padding-right: 0;
}
.u-nopad-sm {
	@include mq(sm) {
		padding: 0;
	}
}

// Margin
.u-margin_tp {
	margin-top: 2rem;
	margin-bottom: 2rem;
}

// Transition
.u-tr {
  transition: $tr;
  -webkit-transition: $tr;
}

// Vertical centering
.u-vmartop--50 { margin-top: 50vh; }

.u-martop--60 { top: 60%; }
.u-martop--50 { top: 50%; }
.u-martop--40 { top: 40%; }
.u-martop--30 { top: 30%; }

.u-skew--50 { transform: translate(0,-50%); }


// Setting height of element to viewport height i.e. ".full_{percentage}"
.u-full,
.u-full_90,
.u-full_75,
.u-full_70
.u-full_65,
.u-full_60,
.u-full_50,
.u-full_15 {
    width: 100%;
    display: inline-block;
    float: left;
}
.u-full_65 {
	height: 65vh;
}

.j-no-scroll {
	@include mq(lg,min) {
		overflow: hidden;
	}
}



// ---------------------------------------------------------------- //
// --- 3. GENERIC ------------------------------------------------- //
// ---------------------------------------------------------------- //

html {
	font-size: 1.5vw;
	@include mq(lg) {
		font-size: 2vw;
	}
	@include mq(md) {
		font-size: 3vw;
	}
	@include mq(sm) {
		font-size: 4vw;
	}
}
body {
  background-color: $white;
  color: $dark;
  font-family: $rale;
  font-weight: 400;
	min-width: $minWidth;
  padding: 0;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  line-height: 1.5em;
  overflow-x: hidden;
	a {
		color: $main;
		&:hover {
			color: $white;
		}
	}
	@include mq($minWidth) {
		overflow-x: visible;
	}
}
::-webkit-input-placeholder {
    color: rgba($dark, 1);
}
::selection {
    background-color: $main;
    color: $white;
}


// ---------------------------------------------------------------- //
// --- 4. ELEMENTS ------------------------------------------------ //
// ---------------------------------------------------------------- //


// header
header {
	position: fixed;
	z-index: 200;
	display: inline-block;
	float: left;
	top: 0;
	left: 0;
	right: 0;
	min-width: $minWidth;
	height: $menuHeight;
	box-sizing: border-box;
	background: rgba($white,1);
	box-shadow: 0px 1px 1px rgba(0,0,0,0.05);
	padding: 12px 0;
	color: $dark;
	transition: $tr;
	transform: translate(0,0);
	-webkit-backface-visibility: hidden;
	>div { // .u-center
		transition: $tr;
		position: relative;
		.logo_section {
			float: left;
			display: inline-block;
			width: auto;
			.logo_section__logo {
				font-family: $rale;
				font-size: 22px;
				font-weight: 600;
				text-transform: uppercase;
				text-decoration: none;
				letter-spacing: 0.1em;
				line-height: 1em;
				color: inherit;
				padding-top: 10px;
				padding-bottom: 10px;
				margin-top: 17px;
				display: inline-block;
				transition: $tr;
			}
		}
		.menu_section {
			display: inline-block;
			float: right;
			.menu_section__item {
				display: inline-block;
				float: left;
				padding: 0;
				margin: 0;
				margin-left: 25px;
				margin-right: 25px;
				a {
					margin-top: 13px;
					padding: 15px 0 2px;
					display: inline-block;
					float: left;
					width: 100%;
					color: $dark;
					transition: $tr;
					border-bottom: 2px solid transparent;
					font: {
						size: 15px;
						weight: 600;
					}
					text: {
						transform: uppercase;
						decoration: none;
					}
					letter-spacing: 0.05em;
					&:hover {
						color: $main;
						border-color: $main;
						transition: none;
					}
				}
				&:last-of-type {
					margin-right: 0;
				}
				&.active {
					a {
						color: $main;
						border-color: $main;
					}
				}
			}
		}
		.edit_section {
			display: inline-block;
			float: left;
			margin-top: 18px;
			margin-left: 35px;
			color: $dark;
			.edit_section__hello {
				margin: 0;
				padding: 0;
				margin-right: 10px;
				font-size: 16px;
				line-height: 1em;
			}
			&__edit {
				text-decoration: none;
				color: inherit;
				i {
					font-size: 23px;
					padding: 7px 10px;
					color: inherit;
					transition: $tr;
				}
				&:hover {
					i {
						transition: none;
						color: $main;
					}
				}
			}
		}
		.menu_button {
			margin-top: 17px;
			appearance: none;
			width: $mbWidth;
			height: $mbHeight;
			cursor: pointer;
			transition: $fastr;
			display: inline-block;
			position: relative;
			float: right;
			background: transparent;
			display: none;
			visibility: hidden;
			>span {
				position: absolute;
				width: 100%;
				top: -1px;
				height: 3px;
				left: 0;
				transition: $fastr;
				-webkit-transition: $fastr;
				background-color: $dark;
				border-radius: 6px;
			}
			#line1 {
				transform: rotate(0deg) translate(0px,decimal-round($mbHeight*0.2));
				transform-origin: 0% 100%;
			}
			#line2 {
				transform: rotate(0deg) translate(0px,decimal-round($mbHeight*0.5));
			}
			#line3 {
				transform: rotate(0deg) translate(0px,decimal-round($mbHeight*0.8));
				transform-origin: 0% 0%;
			}
		}
		.menu_button.button--cross {
			#line1 {
				position: absolute;
				transform: translateZ(0) translate(4px, decimal-round($mbHeight*0.25)) rotate(45deg) !important;
			}
			#line2 {
				visibility: hidden;
				opacity: 0;
			}
			#line3 {
				visibility: visible;
				opacity: 1;
				transform: translateZ(0) translate(4px, decimal-round($mbHeight*0.865)) rotate(-45deg) !important;
			}
		}
	}
	@include mq(md,min) {
		// headroom
		&.headroom--pinned {
			transform: translate(0,0);
		}
		&.headroom--unpinned {
			transform: translate(0,-100%);
		}
		&.headroom--top {
			transform: translate(0,0);
		}
		&.headroom--bottom {
			transform: translate(0,0);
		}

		&.headroom--not-top {
		}
		&.header--sticky {
			transform: translate(0,0) !important;
		}
	}
	@include mq(md) {
		position: fixed;
		overflow: hidden;
		box-shadow: 0px 1px 1px rgba(0,0,0,0.05);
		>div { // .u-center
			.menu_section {
				position: fixed;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				margin: auto;
				background: $main;
				transform: translate(0,105%);
				pointer-events: none;
				visibility: hidden;
				opacity: 0;
				transition: $tr;
				.menu_section__item {
					margin-left: 4%;
					margin-right: 0;
					margin-top: 11px;
					a {
						color: $white;
						border-color: darken($main,10);
						&:hover {
							color: $white;
							border-color: $white;
						}
					}
					&.active {
						a {
							color: $white;
							border-color: $white;
						}
					}
				}
				&.menu--open {
					transform: translate(0,0);
					visibility: visible;
					opacity: 1;
				}
			}
			.menu_button {
				display: inline-block;
				visibility: visible;
				&.button--cross {
					>span {
						background-color: #fff;
					}
				}
			}
		}
	}
	@include mq(xs) {
		overflow: visible;
		height: $menuHeightMob;
		>div { // .u-center
			.logo_section .logo_section__logo {
				margin-top: 4px;
			}
			.edit_section {
				margin-top: 6px;
			}
			.menu_section {
				display: none;
				visibility: hidden;
			}
			.menu_button {
				margin-top: 1px;
			}
			.menu_button.button--cross >span {
				background-color: $dark;
			}
		}
	}
}

.mobileMenu {
	background: rgba($white,0.95);
	color: $dark;
	position: fixed;
	margin: auto;
	top: 0; left: 0;
	right: 0; bottom: 0;
	height: 100%;
	display: inline-block;
	z-index: 10;
	transition: $tr;
	transform: translate(100%,0);
	.mobileMenu__content {
		overflow: hidden;
		position: relative;
		display: block;
		>ul { // .u-center
			width: 100%;
			max-width: 100%;
			padding: 0;
			overflow: auto;
			list-style: none;
			.mobileMenu__content__item {
				display: inline-block;
				float: left;
				width: 100%;
				a {
					color: $dark;
					display: inline-block;
					float: left;
					width: 100%;
					text-align: center;
					font-size: 2rem;
					padding: 2rem 0;
					&:hover {
						text-decoration: none;
					}
				}
				&.active {
					a {
						color: $main;
					}
				}
			}
		}
	}
	&.menu--open {
		transform: translate(0,0);
	}
	@include mq(xs,min) {
		display: none;
		opacity: 0;
		visibility: hidden;
		position: fixed;
	}
}


// footer
footer {
	display: inline-block;
	float: left;
	width: 100%;
	color: $white;
	background: darken($main,5);
	position: relative;
	.footer__cta {
		position: relative;
		text-align: center;
		.footer__cta__schedule {
			width: $shortWidth;
			max-width: 100%;
			height: auto;
			display: inline-block;
			background: $main;
			>div {
				// Both __info and __cta
				padding: 55px;
				display: inline-block;
				max-width: 100%;
			}
			&__info {
				float: none;
				width: auto;
				text-align: left;
				&__title {
					color: $white;
					margin: 0;
					padding: 0;
					display: inline-block;
					width: 100%;
					float: left;
					font-size: 30px;
					font-weight: 600;
					text-transform: uppercase;
					letter-spacing: 0.05em;
					margin-bottom: 10px;
				}
				&__detail {
					color: $white;
					font-weight: 300;
					font-size: 19px;
					text-transform: none;
					letter-spacing: 0.05em;
					a {
						color: inherit;
					}
				}
				&__properties {
					display: inline-block;
					float: left;
					width: 100%;
					border-top: solid 1px darken($main,5);
					padding-top: 10px;
					margin-top: 10px;
					span {
						display: inline-block;
						float: left;
						width: auto;
						margin-right: 15px;
						color: $white;
					}
				}
			}
			&__cta {
				float: right;
				color: $white;
				min-height: 240px;
				&__price {
					margin-top: 15px;
				}
				&__button {
					margin-top: 20px;
				}
			}
		}
	}
	.footer__content {
		border-top: solid 1px rgba(0,0,0,0.07);
		padding-top: 1.25rem;
		.footer__content__section {
			display: inline-block;
			float: left;
			font-size: 17px;
		}
		.footer__content__section--menu {
			@extend .footer__content__section;
			float: right;
			li {
				width: auto;
				display: inline-block;
				float: left;
				margin: 0 15px;
				&:last-of-type {
					margin-right: 0;
				}
				a {
					color: $white;
					text-decoration: none;
					padding-bottom: 7px;
					transition: $tr;
					border-bottom: solid 1px transparent;
					&:hover {
						transition: none;
						border-color: $white;
					}
				}
				&.active {
					a {
						border-color: $white;
					}
				}
			}
		}
	}
	@include mq(sm) {
		.footer__cta {
			.footer__cta__schedule {
				width: 400px;
				>div {
					width: 100%;
					padding: 30px;
					min-height: 0;
					text-align: center;
				}
				.footer__cta__schedule__info {
					.footer__cta__schedule__info__properties {
						span {
							float: none;
							margin-right: 8px;
							margin-left: 8px;
						}
					}
				}
				.footer__cta__schedule__cta {
					padding-top: 0;
				}
			}
		}
		.footer__content {
			text-align: center;
			.footer__content__section {
				width: 100%;
			}
			.footer__content__section--menu {
				width: 100%;
				text-align: center;
				padding-top: 40px;
				padding-left: 15px;
				padding-right: 15px;
				li {
					float: none;
					&:last-of-type {
						margin-right: 15px;
					}
				}
			}
		}
	}
	@include mq(xs) {
		.footer__cta .footer__cta__schedule {
			border: none;
		}
		.footer__content {
			.footer__content__section--menu {
				li {
					width: 100%;
					display: inline-block;
					float: left;
					margin: 0;
					background: darken($main,10);
					a {
						width: 100%;
						display: inline-block;
						float: left;
						padding: 10px 0;
						border-bottom: none;
					}
				}
			}
		}
	}
}

// section
section {
	width: 100%;
	display: inline-block;
	float: left;
}

// e-placeholder
.e-placeholder {
	width: 100%;
	display: inline-block;
	float: left;
	height: 15vh;
}


// ---------------------------------------------------------------- //
// --- 5. OBJECTS ------------------------------------------------- //
// ---------------------------------------------------------------- //


// o-button
.o-button {
	background: transparent;
	appearance: none;
	font-size: 16px;
	font-weight: 700;
	font-family: $rale;
	text-transform: uppercase;
	text-decoration: none;
	display: inline-block;
	padding: 13px 21px;
	border-radius: 10px;
	border: solid 2px $white;
	cursor: pointer;
	color: $white;
	letter-spacing: 0.1em;
	margin-top: 35px;
	transition: $tr;
	&:hover {
		background: $white;
		color: $main;
		border-color: $white;
		transition: none;
		text-decoration: none;
	}
	&:active {
		background: $light;
		border-color: $light;
		color: $dark;
		transition: none;
	}
	&:focus {
		text-decoration: none;
		outline: none;
	}
}
.o-button--dark {
	@extend .o-button;
	color: $main;
	border-color: $main;
	&:hover {
		color: $white;
		border-color: $main;
		background: $main;
	}
	&:active {
		border-color: darken($main,10);
		background: darken($main,10);
	}
}
.o-button--search {
	@extend .o-button;
	appearance: none;
	color: $white;
	background: transparent;
	position: relative;
	margin-top: 0;
	width: 210px;
	box-sizing: border-box;
	padding-right: 50px;
	&:hover {
		background: transparent;
		color: $white;
	}
	&:focus {
		border-color: $main;
		color: $white;
	}
	&~.fa {
		color: $white;
		font-size: 17px;
		position: absolute;
		margin-top: 16px;
		margin-left: -40px;
		pointer-events: none;
	}
}
.o-button--dashed {
	@extend .o-button;
	border-style: dashed;
	opacity: 0.5;
	&:hover {
		opacity: 1;
	}
}
.o-button--small {
	@extend .o-button;
	padding: 10px 18px;
	border: solid 1px transparent;
	border-color: $light;
	color: rgba($dark,0.5);
	margin-top: 17.5px;
	font-size: 0.95em;
	font-weight: 400;
	&:hover {
		background: transparent;
		color: $main;
		border-color: $main;
	}
	&:active {
		color: $white;
		background: $main;
	}
}

// o-mainTitle
.o-mainTitle {
	display: inline-block;
	float: left;
	width: 100%;
	font-size: 28px;
	font-weight: 600;
	color: $main;
	text-transform: uppercase;
	text-decoration: none;
	text-align: center;
}
.o-mainTitle--big {
	@extend .o-mainTitle;
	font-size: 35px;
}

// o-twin
.o-twin {
	background: $main;
	color: $white;
	min-height: 450px;
	position: relative;
	.twin__image {
		display: inline-block;
		float: left;
		position: absolute;
		top: 0;
		bottom: 0;
		background: {
			color: darken($main,15);
			position: center center;
			size: cover;
		}
		&.imageFirst { left: 0; right: 50%; }
		&.imageLast { left: 50%; right: 0; }
	}
	>div { // .u-center
		>div {
			/* Both __empty and __content */
			padding-left: 0;
			padding-right: 0;
		}
		.twin__empty {
			&.imageFirst 	{ float: left; }
			&.imageLast 	{ float: right; }
		}
		.twin__content {
			&.imageFirst 	{ float: right; padding-left: 5%; padding-right: 0; }
			&.imageLast 	{ float: left; padding-left: 0; padding-right: 5%; }
			.twin__content__title {
				display: inline-block;
				float: left;
				width: 100%;
				font-size: 28px;
				font-weight: 600;
				color: $white;
				text-transform: uppercase;
				text-decoration: none;
				text-align: left;
				margin-bottom: 25px;
			}
			.twin__content__description {
				display: inline-block;
				width: 100%;
				line-height: 1.5em;
				margin-bottom: 25px;
				font: {
					size: 17px;
					weight: 300;
				}
			}
			.twin__content__list {
				.twin__content__list__item {
					&:nth-of-type(even) {
						background: rgba(0,0,0,0.02);
					}
					>td {
						/* Both __property and __value */
						padding: 10px;
						font: {
							size: 15px;
							weight: 300;
						}
						text: {
							transform: none;
							decoration: none;
						}
					}
					.twin__content__list__item__property {
					}
					.twin__content__list__item__value {
						font-weight: 600;
					}
				}
			}
		}
		.twin__content--bigger {
			@extend .twin__content;
			font-size: 19px;
		}
	}
	@include mq(sm) {
		.twin__image {
			position: relative;
			width: 100%;
			padding-top: 70%;
			height: 0;
			left: 0 !important;
			right: 0 !important;
		}
		>div {
			.twin__content {
				.twin__content__title {
					text-align: center;
				}
				&.imageFirst { padding-left: 0; padding-right: 0; }
				&.imageLast  { padding-left: 0; padding-right: 0; }
			}
		}
	}
	@include mq(xs) {
		>div {
			.twin__content {
				.twin__content__list {
					width: 100%;
				}
			}
		}
	}
}
.o-twin--noTable {
	@extend .o-twin;
	background: lighten($light,7);
	color: $dark;
	>div { // .u-center
		.twin__content {
			.twin__content__title {
				color: $main;
			}
			.twin__content__description {
				font-size: 18px;
				color: $dark;
				line-height: 1.5em;
				p {
					margin: 15px 0;
				}
			}
		}
	}
}

// o-wideImage
.o-wideImage {
	display: inline-block;
	float: left;
	width: 100%;
	background: {
		color: $dark;
		position: center center;
		size: cover;
	}
	height: 0;
	position: relative;
	padding-top: 50%;
	.wideImage__filter {
		position: absolute;
		top: 0; left: 0;
		right: 0; bottom: 0;
		display: inline-block;
		float: left;
		background: rgba(0,0,0,0.15);
	}
	.wideImage__content {
		position: relative;
		overflow: auto;
		.wideImage__content__title {
			padding: 0;
			margin: 0;
			color: $white;
			text-shadow: rgba(0,0,0,0.1) 0px 3px 3px;
		}
	}
	@include mq(lg,min) {
		padding-top: 0;
		height: 65vh;
	}
	@include mq(lg) {
		position: relative;
		.wideImage__content {
			position: absolute;
			width: 100%;
		}
	}
	@include mq(xs) {
		padding-top: 80%;
		.wideImage__content__title {
			font-size: 2rem;
		}
	}
}

// o-tabber
.o-tabber {
	background: $white;
	color: $dark;
	text-align: center;
	.u-center {
		overflow: auto;
	}
	.tabber__title {
		padding-top: 20px;
	}
	.tabber__description {
		display: inline-block;
		width: $medWidth;
		max-width: 100%;
		margin: 1.25rem auto 2rem auto;
		line-height: 2em;
		font: {
			size: 19px;
			weight: 300;
		}
	}
	.tabber__nav {
		display: inline-block;
		float: left;
		width: 100%;
		text-align: center;
		padding: 0;
		&__tab {
			display: inline-block;
			width: auto;
			margin: 0 7.5px;
			a {
				//background: $light;
				display: inline-block;
				float: left;
				margin-top: 0;
				border-color: transparent;
				position: relative;
				&:before,&:after {
					opacity: 0;
				}
				&:before {
					display: inline-block;
					float: left;
					width: 0;
					height: 0;
					border: solid 10px transparent;
					border-top-color: $main;
					position: absolute;
					transition: $tr;
					bottom: 0;
					left: 0;
					right: 0;
					margin: auto;
					margin-bottom: -20px;
					content: "";
					z-index: 9;
				}
				&:after {
					@extend :before;
					margin-bottom: -17px;
					border-top-color: $white;
					z-index: 10;
				}
				&:hover {
					&:before {
						transition: none;
					}
					&:after {
						transition: none;
						border-top-color: $main;
					}
				}
				&:active {
					&:after, &:before {
						transition: none;
						border-top-color: darken($main,10);
					}
				}
			}
			&.active a {
				border-color: $main;
				&:before,&:after {
					opacity: 1;
				}
				&:active {
					border-color: darken($main,10);
					background: darken($main,10);
				}
			}
		}
	}
	.tabber__content {
		display: inline-block;
		float: left;
		width: 100%;
		background: lighten($light,7);
		.tabber__content__tab {
			min-height: 450px;
			position: relative;
			.tabber__content__tab__image { // .swiperSlider .swiper-container
				display: inline-block;
				float: left;
				position: absolute;
				top: 0;
				bottom: 0;
				background: {
					position: center center;
					size: cover;
				}
				&.imageFirst { left: 0; right: 50%; }
				&.imageLast { left: 50%; right: 0; }
			}
			>div { // .u-center
				>div {
					text-align: left;
					/* Both __empty and __content */
					padding-left: 0;
					padding-right: 0;
				}
				.tabber__content__tab__empty {
					&.imageFirst 	{ float: left; }
					&.imageLast 	{ float: right; }
				}
				.tabber__content__tab__content {
					&.imageFirst 	{ float: right; padding-left: 5%; padding-right: 0; }
					&.imageLast 	{ float: left; padding-left: 0; padding-right: 5%; }
					&__title {
						display: inline-block;
						float: left;
						width: 100%;
						font-size: 28px;
						font-weight: 600;
						color: $dark;
						text-transform: uppercase;
						text-decoration: none;
						text-align: left;
						margin-bottom: 25px;
					}
					&__description {
						display: inline-block;
						width: 100%;
						line-height: 1.5em;
						margin-bottom: 25px;
						font: {
							size: 17px;
							weight: 300;
						}
					}
				}
			}
		}
	}
	@include mq(sm) {
		.tabber__content {
			.tabber__content__tab {
				.tabber__content__tab__image  {
					position: relative;
					width: 100%;
					padding-top: 70%;
					height: 0;
				}
				.tabber__content__tab__content {
					&.imageFirst { padding-left: 0; padding-right: 0; }
					&.imageLast  { padding-left: 0; padding-right: 0; }
					.tabber__content__tab__content__title {
						text-align: center;
					}
				}
			}
		}
		.tabber__nav {
			margin-bottom: 0;
			position: relative;
			&:after {
				display: inline-block;
				content: "";
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				margin: auto;
				margin-bottom: -20px;
				width: 0;
				height: 0;
				border: solid 10px transparent;
				border-top-color: rgba($light,0.5);
			}
			.tabber__nav__tab {
				margin: 0;
				width: 50%;
				box-sizing: border-box;
				border: none;
				float: left;
				a {
					border: none;
					border-radius: 0;
					width: 100%;
					text-align: center;
					box-sizing: border-box;
					background: rgba($light,0.5);
					&:hover {
						color: $dark;
					}
					&:before, &:after {
						display: none;
					}
				}
				&.active {
					a {
						background: $light;
					}
				}
			}
		}
	}
	@include mq(xs) {
		.tabber__nav {
			.tabber__nav__tab {
				a {
					font-size: 12px;
				}
			}
		}
	}
}

// o-content
.o-content {
	background: $white;
	.content__title {
		padding: 2rem 0 1.5rem;
	}
	.content__list {
		width: 100%;
		display: inline-block;
		float: left;
		padding-left: 0;
		.content__list__item {
			width: 100%;
			display: inline-block;
			float: left;
			border-top: solid 1px $light;
			.content__list__item__img {
				width: 25%;
				height: 0;
				padding-top: 12.5%;
				display: inline-block;
				opacity: 0.5;
				float: left;
				filter: grayscale(1);
				filter: grayscale(100%);
				transition: $tr;
				background: {
					position: center center;
					size: 70% auto;
					repeat: no-repeat;
				}
			}
			.content__list__item__content {
				width: 70%;
				height: auto;
				display: inline-block;
				float: right;
				.content__list__item__content__title {
					font: {
						size: 26px;
						weight: 600;
					}
					text: {
						transform: uppercase;
						decoration: none;
						align: left;
					}
					letter-spacing: 0.05em;
					color: rgba($dark,.5);
					transition: $tr;
				}
				.content__list__item__content__description {
					color: rgba($dark,.5);
					line-height: 1.5em;
					transition: $tr;
					font: {
						size: 19px;
						weight: 300;
					}
					text: {
						transform: none;
						decoration: none;
						align: left;
					}
					p {
						margin-top: 15px;
					}
				}
				.content__list__item__content__contact {
					padding: 0;
					li {
						display: inline-block;
						float: left;
						width: auto;
						padding: 0;
						margin: 0;
						margin-right: 25px;
						a {

						}
					}
				}
			}
			&:hover {
				.content__list__item__img {
					opacity: 1;
					filter: grayscale(0);
					filter: grayscale(0%);
				}
				.content__list__item__content {
					.content__list__item__content__title { color: rgba($dark,0.75); }
					.content__list__item__content__description { color: rgba($dark,0.75); }
				}
			}
			&:last-of-type {
				margin-bottom: 2rem;
			}
		}
	}
	@include mq(md) {
		.content__list {
			.content__list__item {
				.content__list__item__content {
					.content__list__item__content__description {
						font-size: 17px;
					}
					.content__list__item__content__contact {
						li {
							margin-right: 12.5px;
						}
					}
				}
			}
		}
	}
	@include mq(sm) {
		.content__list {
			.content__list__item {
				.content__list__item__img {
					margin-top: 50px;
				}
				.content__list__item__content {
					.content__list__item__content__title {
						font-size: 23px;
					}
					.content__list__item__content__description {
						font-size: 15px;
					}
					.content__list__item__content__contact {
						li {
							span.hide {
								display: none;
							}
						}
					}
				}
			}
		}
	}
	@include mq(xs) {
		.content__list {
			.content__list__item {
				padding: 2.5rem 0;
				text-align: center;
				.content__list__item__img {
					margin-top: 0;
					width: 50%;
					padding-top: 25%;
					float: none;
					background-size: contain;
				}
				.content__list__item__content {
					width: 100%;
					.content__list__item__content__title {
						font-size: 18px;
						text-align: center;
						width: 100%;
					}
					.content__list__item__content__description {
						//font-size: 15px;
					}
					.content__list__item__content__contact {
						text-align: center;
						li {
							margin-right: 2px;
							margin-left: 2px;
							float: none;
							span.hide-xs {
								display: none;
							}
						}
					}
				}
			}
		}
	}
}


// ---------------------------------------------------------------- //
// --- 6. COMPONENTS ---------------------------------------------- //
// ---------------------------------------------------------------- //


// c-landing
.c-landing {
	height: 100vh;
	width: 100%;
	position: relative;
	background: {
		color: $light;
		position: center bottom;
		size: cover;
	}
	.landing__filter {
		position: absolute;
		top: 0; left: 0;
		right: 0; bottom: 0;
		margin: auto;
		display: inline-block;
		float: left;
		opacity: 0.25;
		background: {
			color: $color2;
		}
	}
	.landing__content {
		position: relative;
		overflow: auto;
		text-align: center;
		.landing__content__schedule {
			width: $shortWidth;
			max-width: 100%;
			height: auto;
			display: inline-block;
			background: $main;
			>div {
				// Both __info and __cta
				padding: 55px;
				display: inline-block;
				max-width: 100%;
			}
			&__info {
				float: none;
				width: auto;
				text-align: left;
				&__title {
					color: $white;
					margin: 0;
					padding: 0;
					display: inline-block;
					width: 100%;
					float: left;
					font-size: 30px;
					font-weight: 600;
					text-transform: uppercase;
					letter-spacing: 0.05em;
					margin-bottom: 10px;
				}
				&__address {
					color: $white;
					font-weight: 300;
					font-size: 19px;
					text-transform: none;
					letter-spacing: 0.05em;
				}
				&__properties {
					display: inline-block;
					float: left;
					width: 100%;
					padding-top: 10px;
					margin-top: 10px;
					span {
						display: inline-block;
						float: left;
						width: auto;
						margin-right: 15px;
						color: $white;
					}
				}
			}
			&__cta {
				float: right;
				background: darken($main,5);
				color: $white;
				min-height: 240px;
				&__price {

				}
				&__button {
					margin-top: 20px;
				}
			}
		}
	}
	@include mq(sm) {
		.landing__content {
			.landing__content__schedule {
				width: 400px;
				>div {
					width: 100%;
					padding: 30px;
					min-height: 0;
					text-align: center;
				}
				.landing__content__schedule__info {
					.landing__content__schedule__info__properties {
						span {
							float: none;
							margin-right: 8px;
							margin-left: 8px;
						}
					}
				}
			}
		}
	}
	@include mq(xs) {
		.landing__content {
			.landing__content__schedule {
				width: 280px;
				>div {
					padding: 20px;
				}
				.landing__content__schedule__info {
					.landing__content__schedule__info__title {
						font-size: 20px;
					}
					.landing__content__schedule__info__address {
						font-size: 14px;
					}
					.landing__content__schedule__info__properties {
						margin-top: 0;
					}
				}
			}
		}
	}
}

// c-about
.c-about {
	text-align: center;
	.about__title {
	}
	.about__description {
		display: inline-block;
		width: $medWidth;
		max-width: 100%;
		margin: 1.25rem auto 1.5rem auto;
		line-height: 2em;
		font: {
			size: 19px;
			weight: 300;
		}
		p {
			@extend .about__description;
			margin: 0;
			margin-bottom: 15px;
			em {
				font-style: italic;
			}
			strong {
				font-weight: 600;
			}
		}
	}
	.about__details {
		display: inline-block;
		float: left;
		width: 100%;
		padding: 0;
		list-style: none;
		box-sizing: border-box;
		.about__details__item {
			display: inline-block;
			float: left;
			box-sizing: border-box;
			border: 1px solid transparent;
			border-right-color: $light;
			&:last-of-type {
				border-right-color: transparent;
			}
			.about__details__item__icon {

			}
			.about__details__item__property {
				&.big {
					text-transform: uppercase;
					letter-spacing: 0.05em;
				}
				&.small {

				}
			}
			.about__details__item__value {
				margin: 0;
				padding: 10px 0 20px;
				font-size: 30px;
				font-weight: 600;
				color: rgba($main,1);
			}
		}
	}
	.about__buttons {
		padding-bottom: 1rem;
		.about__buttons__button {
			margin-left: 10px;
			margin-right: 10px;
		}
	}
	@include mq(md,min) {
		.about__details .about__details__item {
			&.amount-1 { width: 100%; border-color: transparent !important }
			&.amount-2 { width: 50%; }
			&.amount-3 { width: 33.3333%; }
			&.amount-4 { width: 25%; }
			&.amount-5 { width: 20%; }
			&.amount-6 { width: 16.6666%; }
		}
	}
	@include mq(md) {
		.about__details {
			.about__details__item {
				padding-top: 25px;
				padding-bottom: 10px;
				border-bottom-color: $light;
				&.amount-1 { width: 100%; }
				&.amount-2 { width: 50%; border-bottom-color: transparent; }
				&.amount-3 { width: 33.3333%;
					border-bottom-color: transparent;
				}
				&.amount-4 { width: 50%;
					&:nth-of-type(2n+2) { border-right-color: transparent; }
					&:nth-of-type(3) { border-right-color: $light; border-bottom-color: transparent; }
					&:nth-of-type(4) { border-bottom-color: transparent; }
				}
				&.amount-5 { width: 33.3333%;
					&:nth-of-type(4), &:nth-of-type(5) {
						border-bottom-color: transparent;
						border-right-color: $light;
					}
				}
				&.amount-6 { width: 33.3333%;
					&:nth-of-type(4), &:nth-of-type(5), &:nth-of-type(6) {
						border-bottom-color: transparent;
					}
				}
				&:nth-of-type(3n+3) {
					border-right-color: transparent;
				}
			}
		}
	}
	@include mq(sm) {
		.about__description {
			font-size: 17px;
		}
		.about__details {
			.about__details__item {
				border-bottom-color: $light;
				&:nth-of-type(2n+2) {	border-right-color: transparent; }
				&:nth-of-type(2n+1) {	border-right-color: $light; }
				&.amount-1 { width: 100%; }
				&.amount-2 { width: 50%; }
				&.amount-3 { width: 50%;
					&:nth-of-type(1) { border-bottom-color: $light; }
					&:nth-of-type(2) { border-bottom-color: $light; }
				}
				&.amount-4 { width: 50%; }
				&.amount-5 { width: 50%;
					&:nth-of-type(4) { border-bottom-color: $light; }
				}
				&.amount-6 { width: 50%;
					&:nth-of-type(4) { border-bottom-color: $light; }
				}
			}
		}
	}
}
.c-about--bordertop {
	border-top: solid 1px rgba(0,0,0,0.05);
	padding-top: 2.25rem;
}

// c-contactForm
.c-contactForm {
	background: $white;
	border-top: solid 1px rgba(0,0,0,0.05);
	>div { // .u-center
		width: $medWidth;
		.contactForm__column {
			overflow: auto;
			.contactForm__column__smallHeader {
			}
			.contactForm__column__text {
				display: inline-block;
				float: left;
				width: 100%;
				padding: 20px 0;
				p {
					font-size: 17px;
					line-height: 1.5em;
					text-align: center;
				}
			}
			.contactForm__column__form {
				.contactForm__column__form__input,
				.contactForm__column__form__textarea {
					display: inline-block;
					width: 100%;
					float: left;
					font-size: 17px;
					appearance: none;
					transition: $tr;
					box-shadow: none;
					border: none;
					border-radius: 0;
					border-bottom: solid 1px rgba(0,0,0,0.05);
					background: transparent;
					padding: 17px 60px 15px 0;
					&#preference {
						display: none;
					}
					&:hover {
						border-color: $main;
					}
					&:focus {
						border-color: $main;
						outline: none;
					}
					&:valid {
						color: $main;
						+.contactForm__column__form__valid {
							opacity: 1;
							transform: translate(0,0);
						}
					}
				}
				.contactForm__column__form__valid {
					float: right;
					position: relative;
					margin-right: 25px;
					margin-top: -34px;
					display: inline-block;
					opacity: 0;
					transition: $tr;
					transform: translate(0,10px);
					color: $main;
				}
				.contactForm__column__form__input {
				}
				.contactForm__column__form__textarea {
					resize: none;
					&:valid {
						+.contactForm__column__form__valid {
							margin-top: -75px;
						}
					}
				}
				.contactForm__column__form__button_wrap {
					display: inline-block;
					float: left;
					width: 100%;
					text-align: center;
					.contactForm__column__form__button {

					}
				}
			}
		}
	}
}

// c-map
.c-map {
	height: 50vh;
	background: $main;
	.map__embed {
		background: $main;
		height: 55vh;
	}
}

// c-appointment
.c-appointment {
	display: inline-block;
	float: left;
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	margin: auto;
	width: 500px;
	max-width: 100%;
	background: $white;
	padding-top: $menuHeight;
	transition: $tr;
	.appointment__content {
		padding: 2rem 0;
		max-width: 90%;
		margin-left: auto;
		margin-right: auto;
		color: $main;
		.appointment__content__title {
			color: inherit;
			padding: 0;
			margin: 0;
		}
		.appointment__content__description {
			color: $dark;
			padding: 1.5rem 0;
			display: inline-block;
			float: left;
			width: 100%;
			font-size: 17px;
			text-align: center;
		}
		.appointment__content__form {
			.appointment__content__form__input,
			.appointment__content__form__textarea {
				display: inline-block;
				width: 100%;
				float: left;
				font-size: 17px;
				appearance: none;
				transition: $tr;
				box-shadow: none;
				border: none;
				border-radius: 0;
				border-bottom: solid 1px rgba(0,0,0,0.05);
				background: transparent;
				padding: 17px 60px 15px 0;
				&#preference {
					display: none;
				}
				&:hover {
					border-color: $main;
				}
				&:focus {
					border-color: $main;
					outline: none;
				}
				&:valid {
					color: $main;
					+.appointment__content__form__valid {
						opacity: 1;
						transform: translate(0,0);
					}
				}
			}
			.appointment__content__form__valid {
				float: right;
				position: relative;
				margin-right: 25px;
				margin-top: -34px;
				display: inline-block;
				opacity: 0;
				transition: $tr;
				transform: translate(0,10px);
				color: $main;
			}
			.appointment__content__form__input {
			}
			.appointment__content__form__textarea {
				resize: none;
				&:valid {
					+.appointment__content__form__valid {
						margin-top: -75px;
					}
				}
			}
			.appointment__content__form__button_wrap {
				display: inline-block;
				float: left;
				width: 100%;
				text-align: center;
				.appointmentButton {
					margin-left: 25px;
					&:hover {
						color: $dark;
					}
				}
			}
		}
	}
	@include mq(xs) {
		.appointment__content {
			.appointment__content__title {
				margin-top: 20px;
			}
			.appointment__content__description {
				padding: 2rem 0;
			}
		}
	}
}
.c-appointment--invisible {
	transform: translate(-100%,0);
}
.c-appointment--visible {
	transform: translate(0,0);
}


// ---------------------------------------------------------------- //
// --- 7. TRUMPS -------------------------------------------------- //
// ---------------------------------------------------------------- //
